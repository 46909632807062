import React from 'react'
import {
  ChakraProvider
} from '@chakra-ui/react'
import { Switch, Route, useLocation } from 'react-router-dom'
import Index from 'pages/index'
import About from 'pages/about'
import { AnimatePresence } from 'framer-motion'
import Navbar from 'components/Navbar'
import theme from 'theme.js'

function App () {
  const location = useLocation()

  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path='/about' component={About} />
          <Route path='/' component={Index} />
        </Switch>
      </AnimatePresence>
    </ChakraProvider>
  )
}

export default App
