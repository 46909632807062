import React from 'react'
import { Box } from '@chakra-ui/react'

export default function Section ({ children }) {
  return (
    <Box>
      {children}
    </Box>
  )
}
