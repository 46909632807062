import React from 'react'
import { Box, Text, FormControl, Grid, Textarea, FormLabel, Input, FormErrorMessage, Button, useToast } from '@chakra-ui/react'
import { Formik, Field, Form } from 'formik'
import emailjs from 'emailjs-com'
import * as Yup from 'yup'

export default function ContactForms () {
  const toast = useToast()

  function SendEmail (object) {
    emailjs.send('service_wt97fcm', 'template_ahugmm9', object, 'user_FUCrHhGBrjTTdUAupmqra')
      .then((result) => {
        toast({
          title: 'Message sent',
          status: 'success',
          isClosable: true
        })
      }, (error) => {
        toast({
          title: 'There was an error when sending the message',
          status: 'error',
          isClosable: true
        })
      })
  }

  function validateName (value) {
    let error

    return error
  }
  function validateEmail (value) {
    let error

    return error
  }

  return (
    <Box maxWidth='1000px' px={{ base: 0, lg: '40px' }} mx='auto'>
      <Box fontSize={{ base: '4xl', lg: '6xl' }} mb='40px' textAlign='center'>
        <Text fontSize='5xl' fontWeight='bold'>Contact me!</Text>
      </Box>
      <Formik
        initialValues={{ name: '', email: '', message: '' }}
        validationSchema={Yup.object({
          name: Yup.string().min(2, 'Introduce a correct name').required('Required'),
          email: Yup.string().email('Invalid email address').required('Required'),
          message: Yup.string().min(10, 'Minimum length 15 characters').max(250, 'Max length 250 characters').required('Required')
        })}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            SendEmail(values)
            actions.setSubmitting(false)
          }, 1000)
        }}
      >
        {(props) => (
          <Form>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap='4'>
              <Field name='name' validate={validateName}>
                {({ field, form }) => (
                  <Box>
                    <FormControl isInvalid={form.errors.name && form.touched.name}>
                      <FormLabel htmlFor='name'>First name</FormLabel>
                      <Input {...field} id='name' placeholder='Name' />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  </Box>
                )}
              </Field>
              <Field name='email' validate={validateEmail}>
                {({ field, form }) => (
                  <Box>
                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                      <FormLabel htmlFor='email'>Email</FormLabel>
                      <Input {...field} id='email' placeholder='Email' />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  </Box>
                )}
              </Field>
            </Grid>
            <Field name='message'>
              {({ field, form }) => (
                <Box>
                  <FormControl isInvalid={form.errors.message && form.touched.message}>
                    <FormLabel htmlFor='message'>Message</FormLabel>
                    <Textarea height='200px' {...field} id='message' placeholder='Leave your message here...' />
                    <FormErrorMessage>{form.errors.message}</FormErrorMessage>
                  </FormControl>
                </Box>
              )}
            </Field>
            <Button
              mt={4}
              colorScheme='teal'
              isLoading={props.isSubmitting}
              type='submit'
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
