import React from 'react'
import { Box, Grid, Text, GridItem, Link } from '@chakra-ui/react'
import imgProject1 from 'assets/images/logo_Icuida-06.png'
import imgProject2 from 'assets/images/logo-mv.png'
import FadeInWhenVisible from 'hooks/FadeInWhenVisible'
import { Element } from 'react-scroll'

function Projects (props) {
  const projects = [
    {
      name: 'Marisela Vasquez',
      description: 'Created personal website for Marisela Vasquez to promote her services and expose her abilities as a digital project manager and business consultant.',
      websiteUrl: 'https://mariselavasquez.com/',
      imgPath: imgProject2
    },
    {
      name: 'iCuida',
      description: 'Web application to share information collaboratively by and for home and care workers in Barcelona, a project of the Barcelona Open Data Initiative, in collaboration with the Barcelona City Council, Mobile World Capital Barcelona and Digital Future Society Civil Lab.',
      websiteUrl: 'https://icuida.barcelona/',
      imgPath: imgProject1
    }
  ]

  return (
    <Element name='projects'>
      <FadeInWhenVisible>
        <Box pt='50px'>
          <Box textAlign='center' mb='50px'>
            <Text fontSize='5xl' fontWeight='bold'>Projects</Text>
          </Box>
          <Grid templateColumns={{ base: 'repeat(1,1fr)', md: 'repeat(2, 1fr)' }} gap='4'>
            {projects.map((projectValue, projectIndex) => (
              <GridItem key={projectIndex} mb='15px' h='100%'>
                <Box mx='auto' h='100%' maxW='2xl' borderWidth='1px' borderRadius='sm' overflow='hidden'>
                  <Box
                    height='sm' maxWidth={{ base: '2xl', md: 'md', lg: '2xl' }}
                    bgColor='white'
                    backgroundImage={'url(' + projectValue.imgPath + ')'}
                    backgroundSize='cover'
                    backgroundRepeat='no-repeat'
                    backgroundPosition='center center'
                  />
                  <Box p='6' h='300px'>
                    <Box mb='20px'>
                      <Text transition='.5s' cursor='pointer' fontSize='3xl' _hover={{ fontSize: '4xl' }}>
                        <a target='_blank' href={projectValue.websiteUrl} rel='noreferrer'>
                          {projectValue.name}
                        </a>
                      </Text>
                    </Box>
                    <Box mb='40px'>
                      <Text fontSize={{ sm: 'xl', md: 'l', lg: 'xl' }}>{projectValue.description}</Text>
                    </Box>
                  </Box>
                  <Box textAlign='right' padding='14px 20px'>
                    <Link
                      target='_blank' href={projectValue.websiteUrl}
                      borderStyle='solid'
                      color='white'
                      backgroundColor='#fb2056'
                      p='15px 15px'
                      borderRadius='5px'
                      fontWeight='bold' rel='noreferrer'
                    >
                      Visit site...
                    </Link>
                  </Box>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </FadeInWhenVisible>
    </Element>
  )
}

export default Projects
