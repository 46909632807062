import React from 'react'
import { Box, Text, Flex, useMediaQuery, Icon, IconButton, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, useDisclosure } from '@chakra-ui/react'
import { Link } from 'react-scroll'
import { ColorModeSwitcher } from 'components/ColorModeSwitcher'
import { FaBars, FaTimes } from 'react-icons/fa'

export default function Navbar () {
  const { isOpen, onOpen, onClose } = useDisclosure()

  function MobileNav () {
    return (
      <>
        <Flex justifyContent='space-between' p='10px'>
          <Box alignSelf='center'>
            <Text fontSize='3xl'>John Vincent</Text>
          </Box>
          <Flex cursor='pointer' alignSelf='center'>
            <Box px='20px'>
              <ColorModeSwitcher initialColorMode='dark' />
            </Box>
            <Box onClick={onOpen}>
              <IconButton>
                <Icon as={FaBars} />
              </IconButton>
            </Box>
          </Flex>
        </Flex>
        <Drawer placement='top' onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>
              <Flex justifyContent='space-between'>
                <Box>
                  <Text fontSize='2xl'>
                    John Vincent
                  </Text>
                </Box>
                <Box onClick={onClose}>
                  <Text fontSize='2xl'>
                    <Icon as={FaTimes} />
                  </Text>
                </Box>
              </Flex>
            </DrawerHeader>
            <DrawerBody textAlign='center'>
              <Box overflow='hidden'>
                <Box cursor='pointer' px='30px' alignSelf='center' fontSize='2xl'>
                  <Link to='/' onClick={() => onClose()}>
                    <Text transition='.5s' _hover={{ transform: 'translateY(-3px)' }}>Home</Text>
                  </Link>
                </Box>
                <Box cursor='pointer' px='30px' alignSelf='center' fontSize='2xl'>
                  <Link to='about' smooth duration={1000} onClick={() => onClose()}>
                    <Text transition='.5s' _hover={{ transform: 'translateY(-3px)' }}>About</Text>
                  </Link>
                </Box>
                <Box cursor='pointer' px='30px' alignSelf='center' fontSize='2xl'>
                  <Link to='projects' smooth duration={1000} onClick={() => onClose()}>
                    <Text transition='.5s' _hover={{ transform: 'translateY(-3px)' }}>Projects</Text>
                  </Link>
                </Box>
                <Box cursor='pointer' px='30px' alignSelf='center' fontSize='2xl'>
                  <Link to='contact' smooth duration={2000} onClick={() => onClose()}>
                    <Text transition='.5s' _hover={{ transform: 'translateY(-3px)' }}>Contact</Text>
                  </Link>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  function DesktopNav () {
    return (
      <>
        <Flex height='80px' justifyContent='space-between' px='50px'>
          <Box fontSize='30px' alignSelf='center'>
            <Text>John Vincent</Text>
          </Box>
          <Flex fontSize='20px'>
            <Box cursor='pointer' px='30px' fontWeight='semibold' alignSelf='center'>
              <Link to='/'>
                <Text transition='.5s' _hover={{ transform: 'translateY(-4px)', borderBottom: '1px solid white' }}>Home</Text>
              </Link>
            </Box>
            <Box cursor='pointer' px='30px' fontWeight='semibold' alignSelf='center'>
              <Link to='about' smooth duration={1000}>
                <Text transition='.5s' _hover={{ transform: 'translateY(-4px)', borderBottom: '1px solid white' }}>About</Text>
              </Link>
            </Box>
            <Box cursor='pointer' px='30px' fontWeight='semibold' alignSelf='center'>
              <Link to='projects' smooth duration={1000}>
                <Text transition='.5s' _hover={{ transform: 'translateY(-4px)', borderBottom: '1px solid white' }}>Projects</Text>
              </Link>
            </Box>
            <Box cursor='pointer' px='30px' fontWeight='semibold' alignSelf='center'>
              <Link to='contact' smooth duration={2000}>
                <Text transition='.5s' _hover={{ transform: 'translateY(-4px)', borderBottom: '1px solid white' }}>Contact</Text>
              </Link>
            </Box>
            <Box alignSelf='center'>
              <ColorModeSwitcher initialColorMode='dark' />
            </Box>
          </Flex>
        </Flex>
      </>
    )
  }

  const [isLargerThan1080] = useMediaQuery('(min-width: 1080px)')
  return (
    <>
      {isLargerThan1080 ? DesktopNav() : MobileNav()}
    </>
  )
}
