import React from 'react'
import { Box, Flex, Text, Grid, GridItem } from '@chakra-ui/react'
import FadeInWhenVisible from 'hooks/FadeInWhenVisible'
import { Element } from 'react-scroll'
import ballImage from 'assets/images/me.jpg'
export default function About () {
  return (
    <Element name='about'>
      <Box fontSize='xl'>
        <FadeInWhenVisible>
          <Grid
            height='100%'
            templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
            alignContent='center'
          >
            <GridItem backgroundColor='white' color='black'>
              <Box colSpan={2} pt='80px' alignSelf='center' px={{ base: '20px', md: '80px' }}>
                <Box fontSize='5xl' mb='40px' textAlign='center'>
                  <Text fontWeight='bold'>About me</Text>
                </Box>
                <Box fontSize={{ base: 'xl', lg: '3xl' }} mb='30px' p='0px'>
                  <Text>Hello Everyone, I am John Vincent based in Barcelona.</Text>
                  <Text>A passionate Full Stack Developer having an experience of building Web applications with JavaScript / Reactjs / Nodejs and some other cool libraries and frameworks</Text>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Flex height='100%' alignSelf='center' colSpan={2}>
                <Box
                  alignSelf='center' height={{ base: '400px', lg: '600px' }} width='100%'
                  backgroundImage={`url(${ballImage})`}
                  backgroundSize='cover'
                  backgroundRepeat='no-repeat'
                  backgroundPosition='center center'
                />
              </Flex>
            </GridItem>
          </Grid>
        </FadeInWhenVisible>
      </Box>
    </Element>
  )
}
