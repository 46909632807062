import React from 'react'
import { Box, Text, useColorMode } from '@chakra-ui/react'
import Particles from 'react-particles-js'
import { motion } from 'framer-motion'
import TextLoop from 'react-text-loop'
import About from 'pages/about'
import Projects from 'components/Projects'
import Skillsets from 'components/Skillsets'
import ContactForms from 'components/Forms/ContactForms'
import FadeInWhenVisible from 'hooks/FadeInWhenVisible'
import Footer from 'components/Footer'
import { Element } from 'react-scroll'
import Section from 'components/Sections'

export default function Index () {
  const { colorMode } = useColorMode()

  return (
    <>
      <Box>
        <Box textAlign='center' fontSize='xl'>
          <Box position='relative'>
            <Box pointerEvents='none' fontSize={{ base: '3xl', lg: '6xl' }} position='absolute' width='100%' text-align='center' top='50%' transform='translateY(-50%)'>
              <motion.div
                initial={{ opacity: 0, y: 0 }}
                animate={{ opacity: 1, y: -50 }}
                exit={{ opacity: 0 }}
                transition={{
                  default: { duration: 0.5 }
                }}
              >
                <TextLoop
                  springConfig={{ stiffness: 70, damping: 31 }}
                  adjustingSpeed={500}
                  interval={[5000, 5000]}
                >
                  <Text>Hello, I am John Vincent</Text>
                  <Text>Welcome to my website</Text>
                </TextLoop>!
              </motion.div>
            </Box>
            <Particles
              height='calc(100vh - 80px)'
              params={{
                particles: {
                  number: {
                    value: 80,
                    density: {
                      enable: true,
                      value_area: 1000
                    }
                  },
                  color: {
                    value: colorMode === 'light' ? 'rgba(26,32,44,1)' : 'rgba(226,232,240,1)'
                  },
                  line_linked: {
                    color: colorMode === 'light' ? 'rgba(26,32,44,1)' : 'rgba(226,232,240,1)'
                  },
                  size: {
                    value: 2
                  },
                  move: {
                    speed: 1
                  }
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: false,
                      mode: 'repulse'
                    }
                  }
                }
              }}
            />
          </Box>
        </Box>
        <Section>
          <About />
        </Section>
        <Section>
          <Projects />
        </Section>
        <Section>
          <Skillsets />
        </Section>
        <Section>
          <Element name='contact'>
            <Box fontSize='xl' px={{ base: '20px' }} pb='200px'>
              <FadeInWhenVisible>
                <ContactForms />
              </FadeInWhenVisible>
            </Box>
          </Element>
        </Section>
        <Footer />
      </Box>
    </>
  )
}
