import React from 'react'
import { Box, Text, Flex, List, ListItem, ListIcon } from '@chakra-ui/react'
import { FaHtml5, FaCss3Alt, FaBootstrap, FaReact, FaNode, FaNpm, FaDatabase, FaAws, FaVuejs, FaGithub } from 'react-icons/fa'
import { SiJavascript, SiRedux } from 'react-icons/si'
import FadeInWhenVisible from 'hooks/FadeInWhenVisible'
import { Element } from 'react-scroll'

export default function Skillsets () {
  return (
    <Element name='skillsets'>
      <Box fontSize='xl' mx='50px' py='200px'>
        <Flex height='100%' width='fit-content' mx='auto'>
          <Box alignSelf='center'>
            <FadeInWhenVisible>
              <Box mb='30px' textAlign='center'>
                <Text fontSize={{ base: '5xl', lg: '6xl' }} fontWeight='bold'>Professional skillset</Text>
              </Box>
              <List width='fit-content' mx='auto' textAlign='center'>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaHtml5} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaCss3Alt} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={SiJavascript} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaReact} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={SiRedux} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaVuejs} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaNode} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaNpm} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaDatabase} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaGithub} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaBootstrap} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
                <ListItem cursor='pointer' spacing='10px' display='inline-block'>
                  <ListIcon transition='.5s' _hover={{ transform: 'scale(1.3)' }} m='20px' as={FaAws} width={{ base: '50px', lg: '100px' }} height={{ base: '50px', lg: '100px' }} />
                </ListItem>
              </List>
            </FadeInWhenVisible>
          </Box>
        </Flex>
      </Box>
    </Element>
  )
}
