import React from 'react'
import { Box, Text, Grid, List, ListItem, ListIcon } from '@chakra-ui/react'
import { FaLinkedin, FaGithub } from 'react-icons/fa'

export default function Footer () {
  return (
    <Box>
      <Grid
        mx='auto'
        templateColumns='repeat(3, 1fr)'
        alignContent='center'
        py='20px'
      >
        <Box />
        <Box textAlign='center' fontWeight='semibold'>
          <Text fontSize='lg'>
            Copyright &#169; 2021
          </Text>

        </Box>
        <Box textAlign='center'>
          <List height='40px'>
            <ListItem display='inline-block' px='10px'>
              <a target='_blank' href='https://github.com/jvincent3' rel='noreferrer'>
                <ListIcon height='30px' width='30px' transition='1s' _hover={{ transform: 'scale(1.2)' }} as={FaGithub} />
              </a>
            </ListItem>
            <ListItem display='inline-block' px='10px'>
              <a target='_blank' href='https://linkedin.com/in/vincent-john' rel='noreferrer'>
                <ListIcon height='30px' width='30px' transition='1s' _hover={{ transform: 'scale(1.2)' }} as={FaLinkedin} />
              </a>
            </ListItem>
          </List>
        </Box>
      </Grid>
    </Box>
  )
}
